import styles from '../AccountMenu.module.scss';
import { LoginLink } from 'components/primitives/links';

const AnonymousMenuItems = () => {

  return (
    <>
      <LoginLink className={styles.item} />
    </>
  );
};

export default AnonymousMenuItems;
