import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { MyAccountLink } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import LogoutButton from './LogoutButton';

const CustomerMenuItems = ({ logoutDelay }) => {
  const name = useSelector(state => state.user.name);

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      <MyAccountLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

CustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default CustomerMenuItems;
