import { useSelector, useDispatch } from 'react-redux';
import { subscribeToNewsletter } from 'behavior/pages/newsletter';
import { useSuccessMessage } from '../hooks';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { FormName } from 'behavior/pages';
import PropTypes from 'prop-types';
import { useCaptchaOnSubmit } from 'components/objects/forms';

const NewsletterSubscription = ({ id, model, isDesignerMode }) => {
  const dispatch = useDispatch();
  const { lastUpdated, updatedById } = useSelector(state => state.newsletter);
  const formName = `${FormName.NewsletterSubscribe}_${id}`;
  const subscribedByComponent = formName === updatedById;
  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    dispatch(subscribeToNewsletter(formData, formName));
  }, FormName.NewsletterSubscribe, [formName]);

  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');
  return (
   <></>
  );
};

NewsletterSubscription.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
    subscribeBtnDisplayMode: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
const NewsletterSubscriptionBlock = ({ isDesignerMode, ...props }) => {
  const [canSubscribe] = useHasAbilities(AbilityTo.SubscribeToNewsletter);

  if (!isDesignerMode && !canSubscribe)
    return null;

  return <NewsletterSubscription isDesignerMode={isDesignerMode} {...props} />;
};

NewsletterSubscriptionBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
    subscribeBtnDisplayMode: PropTypes.number.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default NewsletterSubscriptionBlock;
